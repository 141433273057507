<template>
  <div style="max-width: 700px" class="mx-auto">
    <div v-if="!standalone" class="text-center">
      <h2 class="mb-4">{{ document.title }}</h2>
      <p class="text-medium-emphasis">
        {{ document.description }}
      </p>
    </div>
    <v-row class="py-4 py-sm-8">
      <v-col
        v-for="item in document.items"
        :key="item.title"
        cols="12"
        :sm="12 / document.items.length"
      >
        <v-card
          class="text-center"
          :class="{ 'pricing-card-primary-border': item.plan === 'paid' }"
        >
          <v-card-text>
            <h2 class="text-h5 mb-2">
              {{ item.title }}
            </h2>
            <p class="mb-0">
              {{ item.description }}
            </p>
          </v-card-text>
          <v-card-text>
            <div class="d-flex justify-center align-center">
              <sup class="font-weight-medium">
                {{ item.priceUnit }}
              </sup>
              <h1 class="text-h3 font-weight-medium text-primary">
                {{ item.price }}
              </h1>
              <sub class="font-weight-medium mt-4">
                /{{ item.pricePeriod }}
              </sub>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="pt-0">
            <v-list class="text-left">
              <v-list-item
                v-for="feature in item.features"
                :key="feature.title + feature.icon"
                :value="feature.title"
                :link="false"
              >
                <template v-slot:prepend>
                  <v-icon
                    v-if="feature.icon === 'mdi-check'"
                    color="success"
                    size="small"
                    start
                  >
                    {{ mdiCheck }}
                  </v-icon>
                  <v-icon v-else color="error" small>
                    {{ mdiClose }}
                  </v-icon>
                </template>
                <v-list-item-title>
                  {{ feature.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center py-4 py-sm-8">
      <div v-show="store.isAuthenticated">
        <h6 class="text-h5 mb-4">{{ $t('PricingCards.calculatorTitle') }}</h6>
        <p>{{ $t('PricingCards.calculatorDescription') }}</p>
      </div>
      <pricing-calculator class="mb-8"></pricing-calculator>
      <select-plan-btn></select-plan-btn>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/composables/store'
import { mdiClose, mdiCheck } from '@mdi/js'

const store = useStore()

const [{ data: document }] = await Promise.all([
  useAsyncData(() => queryContent('pricing/').findOne()),
])

defineProps({
  standalone: {
    type: Boolean,
    default: true,
  },
})
</script>
